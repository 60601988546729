import React from "react"
import data from "../data/nationalParks.json"

export const NationalParks = () => {
    const parksAmount = data.nationalParks.length
    const parksVisitedAmount = data.nationalParks.filter(park => park.visited).length
    return (
        <div className="text-box">
            <div className="background-layer"></div>
            <div>
                <h2>National parks in Finland</h2>
                <p>I'm planning to visit all national parks in Finland. Here is a list of them and I've marked which ones I've already conquered.</p>
                <h4>I have visited {parksVisitedAmount} parks out of {parksAmount}.</h4>
                <ul className="parks">
                    {data.nationalParks.map((park, i) => {
                        return (
                            <li key={i} className={`park ${park.visited ? 'visited' : 'not-visited'}`}>{park.name}</li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}