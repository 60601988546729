import React from 'react'

export default ({ style }) => {
    return (
        <div className='tree' style={style}>
            <div className='leaf'></div>
            <div className='leaf'></div>
            <div className='leaf'></div>
            <div className='trunk'></div>
        </div>
    )
}