import React, { useRef, useEffect, useState } from 'react'
import Tree from './tree'

const getRandomPosition = () => {
    const maxX = window.innerWidth
    const maxY = document.querySelector('.forest')?.scrollHeight
    const randomX = Math.random() * maxX
    const randomY = Math.random() * maxY
    return {x: randomX, y: randomY}
}

const getRandomScale = () => {
    return Math.random() * 0.5 + 1
}

// const getBlurValue = y => {
//     let blurValue = 0
//     if (y < window.innerHeight / 3) {
//         blurValue = 2
//     } else if ( y < (window.innerHeight / 3 * 2)) {
//         blurValue = 1
//     }
//     return blurValue
// }

const getTreeStyles = () => {
    const position = getRandomPosition()
    const blurValue = 0
    const inlineStyle = {position: 'absolute', transform: `scale(${getRandomScale()}) translateY(${position.y}px) translateX(${position.x}px)`, zIndex: Math.round(position.y), transformOrigin: 'bottom', filter: `blur(${blurValue}px)`}
    return inlineStyle
}


export const Forest = () => {
    const forestRef = useRef()
    const [treesAmount, setTreesAmount] = useState(0)
    const treesArray = Array(treesAmount).fill().map((_, i) => i+1)

    useEffect(() => {
        if (forestRef.current) {
            setTreesAmount(40)
        }
    }, [forestRef])

    return (
    <div className='forest' ref={forestRef}>
        {treesArray.map((tree, i) => { 
            return <Tree key={i} style={getTreeStyles()}/> 
        })}
    </div>
    )
}