import React from 'react'
import { Helmet } from "react-helmet"
import { Forest } from '../forestComponents/forest'
import { NationalParks } from '../components/nationalParks'
import { Intro } from '../components/intro'
import { Interests } from '../components/interests'
import { Layout } from '../layout/layout'
import '../styles/index.scss'

export default () => {
    return (
        <Layout>
            <Helmet>
                <title>Kursori exploring the World!</title>
                <meta name="description" content="Kursori is a nature lover from Finland, who likes to explore new places." />
            </Helmet>
            <div className="page grid">
                <div className="col-6_sm-12" data-push-left="off-6_sm-0">
                    <Intro />
                </div>
                <div className="col-6_sm-12">
                    <Interests />
                </div>
                <div className="col-6_sm-12" data-push-left="off-6_sm-0">
                    <NationalParks />
                </div>
                <div className="col-6_sm-12">
                    <div className="text-box">
                        <div className="background-layer"></div>
                        <h2>Thank you for your time!</h2>
                        <p>That's all for now. I'll keep adding stuff here when I feel inspired to tinker with some code. </p>
                        <p>In the meanwhile, you can drop me a message on <a href="https://instagram.com/kursori" rel="noopener" target="_blank">Instagram</a> if you want to chat up.</p>
                    </div>
                </div>
                <Forest></Forest>
            </div>
        </Layout>
    )
}