import React from "react"

export const Intro = () => {
    return (
    <div className="text-box">
        <h1>Welcome to my digital forest!</h1>
        <p>Welcome to my humble little site, a HTML + CSS forest of mine! I am Kursori and I like to go and explore my surroundings. 
            Most often I head out to enjoy the nature near by, but when
             possible, I love to do over night trips and visit places further away, like finnish national parks.</p>
        <div className="background-layer"></div>
    </div>
    )
}