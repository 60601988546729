import React from "react"

const interests = [
    'nature',
    'exploring',
    'adventures',
    'simple living',
    'backpacking',
    'hiking',
    'bushcraft',
    'survival',
    'raw food',
    'biking',
    'paleo food',
    'running',
    'mono meals',
    'minimalism',
    'camping'
]

export const Interests = () => {
    return (
    <div className="text-box">
        <div className="background-layer"></div>
        <h2>Some things I'm interested in:</h2>
        <ul className="interests">
            {interests.map((interest, i) => {
                return <li key={i}>{interest}</li>
            })}
        </ul>
    </div>
    )
}